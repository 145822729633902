import { createContext, useContext, PropsWithChildren } from 'react';
import useTenantTheme, { IProps as IUseTenantThemeProps } from './useTenantTheme';

type TenantThemeContextType = ReturnType<typeof useTenantTheme>;

export const TenantThemeContext = createContext<TenantThemeContextType>(null);

export const useTenantThemeContext = (): TenantThemeContextType => {
  const context = useContext<TenantThemeContextType>(TenantThemeContext);
  if (!context) {
    throw new Error('useTenantThemeContext must be used within a TenantThemeContextProvider');
  }
  return context;
};

const TenantThemeContextProvider = ({ children, ...props }: PropsWithChildren<IUseTenantThemeProps>) => (
  <TenantThemeContext.Provider value={ useTenantTheme(props) }>
    { children }
  </TenantThemeContext.Provider>
);

export default TenantThemeContextProvider;

import { setCssVariablesToRoot } from '@/Framework/dom/setCssVariablesToRoot';
import { ITenantTheme } from '@/users/domain/vo/ITenantTheme';
import { DEFAULT_THEME } from './constants';

export interface IProps {
  tenantTheme?: ITenantTheme,
}

const useTenantTheme = ({ tenantTheme = DEFAULT_THEME }: IProps): ITenantTheme => {
  const { backgroundColor, backgroundImage } = tenantTheme.background;
  const variables = {
    ...tenantTheme.colors,
    backgroundColor,
    backgroundImage: backgroundImage?.url ? `url(${ backgroundImage.url })` : '',
  };
  setCssVariablesToRoot(variables);

  return tenantTheme;
};

export default useTenantTheme;
